.vm-pwa {
  text-align: center;
}

.vm-logo {
  height: 40vmin;
  transform-style: preserve-3d;
  transition: all 1s ease-in-out;
}

.vm-logo:active {
  transform: rotateY(360deg);
}

.vm-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  font-family: "Didact Gothic", sans-serif;
  background: radial-gradient(ellipse at 50% 10%, #e3e1e1, #a3a3a3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 2px 6px #000000c7);
}

p {
  color: grey;
}
